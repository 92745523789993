<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Organization overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="organization">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert dense type="warning" v-if="!organizationSummary.brandprofile">
                        <router-link :to="{ name: 'organization-edit-brandprofile', params: { organizationId: this.$route.params.organizationId } }" class="white--text">Set up a BrandProfile</router-link> to customize your storefront.
                    </v-alert>
                    <v-alert dense type="warning" v-if="!organizationSummary.stripe_account_id">
                        <router-link :to="{ name: 'organization-edit-stripe', params: { organizationId: this.$route.params.organizationId } }" class="white--text">Set up a Stripe account</router-link> to accept payments.
                    </v-alert>
                    <v-card>
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Organization overview</v-app-bar-title>
                            <v-spacer></v-spacer>
                            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                        <v-list-item :to="{ name: 'organization-edit-storefront-settings', params: { organizationId: this.$route.params.organizationId } }">
                                            <v-list-item-icon>
                                                <font-awesome-icon :icon="['fas', 'globe']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Storefront</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item :to="{ name: 'organization-settings', params: { organizationId: this.$route.params.organizationId } }">
                                            <v-list-item-icon>
                                                <font-awesome-icon :icon="['fas', 'cog']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Settings</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </v-app-bar>
                        <v-card-text>
                            <!-- TODO: show some useful business charts and data here, monthly recurring revenue, or total sales volume this month to date, this quarter to date, this year to date, vs last month/quarter/year, etc. -->
                            <!-- TODO: it's more useful to show # of all PENDING orders; then we can have a link to see ALL of them also, but we don't need the count of all orders here, that number will just be getting bigger and it's not as useful as a chart showing the total and the # pending ,# recently completed, etc. ; also the search page for all the records is linked from the navbar menu -->
                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'inbox']" :to="{ name: 'organization-search-order', query: { status: 'paid' }, params: { organizationId: this.$route.params.organizationId } }">Pending orders</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.pending_order_count }})</span>
                                <span v-if="organizationSummary && organizationSummary.pending_order_count > 0" class="amber--text text--darken-2 ml-2"><font-awesome-icon :icon="['fas', 'exclamation-triangle']"></font-awesome-icon></span>
                            </p>

                            <v-divider class="mt-6 mb-4"></v-divider>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'inbox']" :to="{ name: 'organization-search-order', params: { organizationId: this.$route.params.organizationId } }">All orders</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.order_count }})</span>
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'paper-plane']" :to="{ name: 'organization-search-invoice', params: { organizationId: this.$route.params.organizationId } }">All invoices</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.invoice_count }})</span>
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'gem']" :to="{ name: 'organization-search-payment', params: { organizationId: this.$route.params.organizationId } }">All payments</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.payment_count }})</span>
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :to="{ name: 'organization-search-refund', params: { organizationId: this.$route.params.organizationId } }"><template #icon-before>
                                        <!-- Reference: https://fontawesome.com/docs/web/use-with/vue/style -->
                                        <font-awesome-layers class="fa-md mr-3">
                                            <font-awesome-icon :icon="['fas', 'gem']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                                            <font-awesome-icon :icon="['fas', 'slash']" class="grey--text text--darken-3" transform="up-2" fixed-width size="sm"></font-awesome-icon>
                                        </font-awesome-layers>
                                    </template>All refunds</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.refund_count }})</span>
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'file-alt']" :to="{ name: 'organization-search-account', params: { organizationId: this.$route.params.organizationId } }">All accounts</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.account_count }})</span>
                            </p>

                            <!-- NOTE: users are CUSTOMERS; users working for the enterprise are considered ADMINISTRATORS, see below for that -->
                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'file-user']" :to="{ name: 'organization-search-user', params: { organizationId: this.$route.params.organizationId } }">All users</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.user_count }})</span>
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'concierge-bell']" :to="{ name: 'organization-search-service', params: { organizationId: this.$route.params.organizationId } }">All purchased account services</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.service_count }})</span>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-0">Orders</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-order', params: { organizationId: this.$route.params.organizationId } }">See all orders</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.order_count }})</span>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-0">Invoices</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-invoice', params: { organizationId: this.$route.params.organizationId } }">See all invoices</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.invoice_count }})</span>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-0">Payments</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-payment', params: { organizationId: this.$route.params.organizationId } }">See all payments</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.payment_count }})</span>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-0">Refunds</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-refund', params: { organizationId: this.$route.params.organizationId } }">See all refunds</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.refund_count }})</span>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-0">Customer Accounts</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-account', params: { organizationId: this.$route.params.organizationId } }">See all customer accounts</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.account_count }})</span>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-0">Customer Users</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-user', params: { organizationId: this.$route.params.organizationId } }">See all users</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.user_count }})</span>
                            </p>
                            -->

                            <!-- <p class="text-overline mb-0 mt-0">Purchased Services</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-service', params: { organizationId: this.$route.params.organizationId } }">See all services</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.service_count }})</span>
                            </p> -->

                            <v-divider class="mt-6 mb-4"></v-divider>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'cube']" :to="{ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } }">All products</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.product_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'cubes']" :to="{ name: 'organization-search-product-group', params: { organizationId: this.$route.params.organizationId } }">All product groups</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.product_group_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'cut']" :to="{ name: 'organization-search-coupon', params: { organizationId: this.$route.params.organizationId } }">All coupons/discounts</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.coupon_count }})</span>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-0">Products</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } }">See all products</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.product_count }})</span>
                            </p> -->

                            <!-- NOTE: administrators are users who work for the enterprise -->
                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'user-friends']" :to="{ name: 'organization-search-admin', params: { organizationId: this.$route.params.organizationId } }">All administrators</NavButton>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.admin_count }})</span>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-0">Administrators</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-admin', params: { organizationId: this.$route.params.organizationId } }">See all administrators</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.admin_count }})</span>
                            </p> -->

                            <!-- <template v-if="organizationSummary && (organizationSummary.domain_dispute_claimant_count > 0 || organizationSummary.domain_dispute_defendant_count > 0)">
                            <p class="text-overline mb-0 mt-8">Disputes</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-domain-dispute', params: { organizationId: this.$route.params.organizationId }, query: { role: 'claimant' } }">See all disputes for a domain name</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.domain_dispute_claimant_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-domain-dispute', params: { organizationId: this.$route.params.organizationId }, query: { role: 'defendant' } }">See all disputes against you</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.domain_dispute_defendant_count }})</span>
                            </p>
                            </template> -->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import UserList from '@/components/organization-dashboard/UserList.vue';
import NavButton from '@/components/NavButton.vue';

export default {
    components: {
        // UserList,
        NavButton,
    },
    data: () => ({
        organizationSummary: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            organization: (state) => state.organization,
            focus: (state) => state.focus,
        }),
        name() {
            return this.organization?.name ?? 'Unknown';
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    watch: {
        focus() {
            this.loadOrganizationSummary();
        },
    },
    methods: {
        async loadOrganizationSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganizationSummary: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.check({ item: 'summary' });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organizationSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"domain_dispute_defendant":0,"domain_dispute_claimant":0,"dns_record_count":4}
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganizationSummary: false });
            }
        },
    },
    mounted() {
        this.loadOrganizationSummary();
    },
};
</script>
